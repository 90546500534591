import { Form, FloatingLabel } from "react-bootstrap";
import Style from "./FormPayment.module.scss";
import useFormPayment from "./useFormPayment";
import { useParams } from "react-router-dom";
import { Frames, CardNumber, ExpiryDate, Cvv } from "frames-react";
import { useRtl } from "../../logic/useRtl";

const Formpayment = ({ userData, basicData, orderId }) => {
  let paymentOptionStatus = userData?.data?.payment_option;
  const { rtl } = useRtl({ Style });

  const { lang: langSlug } = useParams();

  const {
    handleCardholderName,
    onCardValidationChanged,
    handleOnCardTokenized,
    payButtonDisabled,
    cardholderName,
    loadingShimmer,
    errorMsgCard,
    cardClick,
    setLoadingShimmer,
    t,
    getDropList,
  } = useFormPayment({ id: orderId });

  const PRODUCTION = process.env.REACT_APP_PRODUCTION;
  const REACT_APP_CHECKOUT_PUBLIC_KEY = 
    PRODUCTION === "true"
      ? process.env.REACT_APP_CHECKOUT_PUBLIC_KEY
      : process.env.REACT_APP_CHECKOUT_PUBLIC_KEY_TEST;
  return (
    <div
      className={`${Style.payment}  ${rtl}`}
      style={{
        display:
          paymentOptionStatus == "1" || paymentOptionStatus == "2"
            ? ""
            : "none",
      }}
    >
      {basicData?.data?.card_payment == "1" && (
        <div className={Style.checkbox}>
          <Form.Check
            type="radio"
            name="type"
            id="card"
            defaultChecked={true}
            label={""}
          />
          <div className={Style.check_label}>
            <div>
              <p>{t("cardType")}</p>
              <span>{t("cardPay")}</span>
            </div>
          </div>
        </div>
      )}
      <Frames
        config={{
          debug: false,
          publicKey: REACT_APP_CHECKOUT_PUBLIC_KEY,
          localization: langSlug == "ar" ? "AR" : "EN-GB",
          style: {
            base: {
              direction: langSlug == "ar" ? "rtl" : "ltr",
            },
          },
          cardholder: {
            name: cardholderName,
          },
        }}
        cardValidationChanged={onCardValidationChanged}
        cardTokenized={handleOnCardTokenized}
        name={cardholderName}
      >
        <div
          className={Style.payment_input}
          style={{ display: !cardClick && "none" }}
        >
          <div className={Style.input_wrap}>
            <FloatingLabel controlId="cardNumber" className={Style.form_group}>
              <CardNumber className={`${Style.form_control} form-control`} />
            </FloatingLabel>
            <div className={Style.dropdown_wrap}>
              <FloatingLabel
                className={`${Style.form_group} ${Style.dropdown}`}
              >
                <ExpiryDate className={`${Style.form_control} form-control`} />
              </FloatingLabel>
            </div>
          </div>
          <div className={Style.input_wrap}>
            <FloatingLabel controlId="cardHolder" className={Style.form_group}>
              <Form.Control
                type="text"
                id="cardholder-name"
                placeholder={t("Card_Holder_Name")}
                className={Style.form_control}
                onChange={handleCardholderName}
                required
              />
            </FloatingLabel>
            <div className={Style.dropdown_wrap}>
              <FloatingLabel
                controlId="Security"
                className={`${Style.form_group} ${Style.dropdown}`}
              >
                <Cvv className={`${Style.form_control} form-control`} />
              </FloatingLabel>
            </div>
          </div>
        </div>
        <div className={Style.btn_wrap}>
          <button
            type="submit"
            className={`${
              loadingShimmer
                ? "loading custom_btn custom_btn-primary"
                : "custom_btn custom_btn-primary"
            }`}
            id="payButton"
            disabled={payButtonDisabled}
            onClick={() => {
              Frames.submitCard();
              setLoadingShimmer(true);
            }}
          >
            {t("PAY")}
          </button>
        </div>
      </Frames>
      <small className={`error`}>
        {errorMsgCard && <span>{errorMsgCard}</span>}
      </small>
    </div>
  );
};

export default Formpayment;
