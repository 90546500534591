import { useFormik } from "formik";
import { setCookie } from "nookies";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ProfileLogin } from "./api";

const useLoginForm = () => {
  const { t } = useTranslation(["common"]);

  const [showModal, setShowModal] = useState(false);
  const [showModalRegister, setShowModalRegister] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const mobileRef = useRef({});

  const handleClose = () => {
    setShowModal(false);
    document.documentElement.classList.remove("no-scroll");
  };

  const handleCloseRegister = () => {
    setShowModalRegister(false);
  };

  const handleShowRegister = () => {
    setShowModalRegister(true);
  };

  const handleShow = () => {
    setShowModal(true);
    setIsTimerRunning(true);
    document.documentElement.classList.add("no-scroll");
  };
  var numbers = /^\d+\.?\d*$/;

  const formik = useFormik({
    initialValues: {
      mobile: "",
    },

    validationSchema: Yup.object({
      mobile: Yup.string()
        .matches(numbers, t("Invalid_mobile"))
        .required(t("Mobile_required"))
        .min(8, t("min"))
        .max(9, t("max")),
    }),
    onSubmit: (values) => {
      let data = {
        mobile: values?.mobile,
        language: localStorage.getItem("language_type"),
      };

      mobileRef.current = values?.mobile;
      setIsSubmitLoading(true);
      ProfileLogin(data)
        .then((response) => {
          if (response?.data?.code == "200") {
            setIsSubmitLoading(false);
            setCookie(null, "userID", response?.data?.data?.user_code);
            window.adobeDataLayer.push({
              event: "deliverysubscription",
              userInfo: {
                phone: values?.mobile,
              },
              identityMap: {
                ECID: [
                  {
                    id: "adobeECID",
                    authenticatedState: "ambiguous",
                    primary: false,
                  },
                ],
                PHONE: [
                  {
                    id: values?.mobile,
                    authenticatedState: "authenticated",
                    primary: true,
                  },
                ],
              },
            });
            handleShow();
          } else {
            setIsSubmitLoading(false);
            formik.setFieldError(
              "mobile",
              response?.errors?.mobile || response?.message
            );
          }
        })
        .catch(() => {
          setIsSubmitLoading(false);
        });
    },
  });

  return {
    t,
    formik,
    showModal,
    mobileRef,
    isTimerRunning,
    isSubmitLoading,
    showModalRegister,
    handleClose,
    setIsTimerRunning,
    handleShowRegister,
    handleCloseRegister,
  };
};

export default useLoginForm;
