import React from "react";
import Style from "../Home/Home.module.scss";
import { useParams } from "react-router-dom";

const TermsandConditions = ({ getTextById }) => {
  const ids = Array.from({ length: 12 }, (_, index) => index + 22);
  const remainingIds = Array.from({ length: 4 }, (_, index) => index + 51);
  const { lang: langSlug } = useParams();
  const lang = localStorage.getItem("language_type") ?? langSlug;
  return (
    <div
      dir={lang === "en" ? "ltr" : "rtl"}
      className={Style.termsandcondition}
    >
      <h2 className="h2">{getTextById(21)}</h2>
      <p>{getTextById(60)}</p>
      <ol>
        {[...ids, ...remainingIds].map((id) => (
          <li key={id}>
            <p>{getTextById(id)}</p>
          </li>
        ))}
      </ol>
      <h3 className="h3">{getTextById(55)}</h3>
      <p>{getTextById(56)}</p>
      <ul>
        <li>{getTextById(57)}</li>
        <li>{getTextById(58)}</li>
      </ul>
      <p>{getTextById(59)}</p>
    </div>
  );
};

export default TermsandConditions;
