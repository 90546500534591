import { useEffect, useState } from "react";

const useCommonLayout = () => {
  const PRODUCTION = process.env.REACT_APP_PRODUCTION;
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      PRODUCTION === "true"
        ? process.env.REACT_APP_ADOBE_CDP_PRODUCTION
        : process.env.REACT_APP_ADOBE_CDP_STAGING;
    script.id = "adobe-cdp";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  let modAppLocation = window?.location?.href?.split("?")[1]?.split("=");
  const [modeCokkie, setModeCookie] = useState();

  function getCookie(name) {
    let cookie = {};
    document.cookie.split(";").forEach(function (el) {
      let [k, v] = el.split("=");
      cookie[k.trim()] = v;
    });
    return cookie[name];
  }

  function setCookie(cName, cValue, expDays) {
    let date = new Date();
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
  }

  useEffect(() => {
    if (modAppLocation?.[0] == "mode" && getCookie("mode") == undefined) {
      setCookie(modAppLocation?.[0], modAppLocation?.[1], 1);
      setModeCookie(modAppLocation?.[1]);
    } else {
      setModeCookie(getCookie("mode"));
    }
  }, []);
  return {modeCokkie};
};

export default useCommonLayout;
